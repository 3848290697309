import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import ErrorBoundary from '../Error';
import * as ROUTES from '../../constants/routes';
//import TermsServicesPopup from '../Terms';
import { ThemeProvider } from '@material-ui/core/styles';
import metaTheme from '../Theme';

const HomePage = lazy(() => import('../Home'));
const LoginPage = lazy(() => import('../SignIn'));
const LogoutPage = lazy(() => import('../Signout'));
const SystemAlerts = lazy(() => import('../Alerts'));
const LoadingBlock = lazy(() => import('../Loading'));
const AdminPage = lazy(() => import('../Admin'));

//const TermsServicesPopup = lazy(() => import('../Terms'));

const App = () => (
  <ThemeProvider theme={metaTheme}>
    <Router>
      <div>
        <Suspense fallback={<LoadingBlock />}>
          <Switch>
            <ErrorBoundary>
              <Route exact path={ROUTES.LANDING} component={HomePage} />
              <Route exact path={ROUTES.LANDINGAUTH} component={HomePage} />
              <Route exact path={ROUTES.ADMIN} component={AdminPage} />
              <Route exact path={ROUTES.LOGIN} component={LoginPage} />
              <Route exact path={ROUTES.LOGOUT} component={LogoutPage} />

              <SystemAlerts />
            </ErrorBoundary>
          </Switch>
        </Suspense>
      </div>
      {/* <TermsServicesPopup /> */}
    </Router>
  </ThemeProvider>
);

export default App;
