const initState = {
    technologyError: null,
    technologyMessage: null,
}

const technologyReducer = (state = initState, action) => {
    switch (action.type) {
        case 'TECHNOLOGY_ADDED':
            return {
                ...state,
                technologyError: null,
                technologyMessage: 'Technology has been added'
            }
        case 'TECHNOLOGY_IMAGE_WAS_UPLOADED':
            return {
                ...state,
                technologyError: null,
                technologyMessage: 'Technology image has been uploaded'

            }
        case 'TECHNOLOGY_ALREADY_ADDED':
            return {
                ...state,
                technologyError: null,
                technologyMessage: 'Technology is already in the system'

            }
        case 'TECHNOLOGY_WAS_NOT_ADDED_TO_DATABASE':
            return {
                ...state,
                technologyError: action.err.message,
                technologyMessage: 'Technology Failed to be hadded to Database'

            }

        case 'TECHNOLOGY_IMAGE_FAILED_TO_UPLOAD':
            return {
                ...state,
                technologyError: action.err.message,
                technologyMessage: 'Technology Image Failed to be uploaded to the server'

            }
        default:
            return state;
    }
}

export default technologyReducer;