import { grey, red } from '@material-ui/core/colors';
import { lighten } from '@material-ui/core/styles';

const greyText = '#707070';
const lightGrey = '#E6E7E8';
const soltaColor = '#5A9BAB';
const white = '#fff';
const list = soltaColor;
const bronze = '#8E755B';
const silver = '#909291';
const gold = '#A87D35';
const platinum = '#909291';
const emerald = '#009975';
const ruby = '#AE1950';
//const diamond = '#CBD1DA'; //Original
const diamond = '#909291';

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  soltaColorFill: {
    color: soltaColor,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    color: grey[700],
  },
  right: {
    textAlign: 'right',
  },
  list: {
    width: 250,
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },
  paper: {
    padding: 40,
    width: 800,
  },
  textField: {
    marginLeft: 1,
    marginRight: 1,
    width: '100%',
  },
  formControl: {
    margin: 10,
    minWidth: 120,
    width: '100%',
  },
  fullWidthForm: {
    width: '100%',
    //height: '100%',
    //position: 'relative',
  },
  selectEmpty: {
    marginTop: 20,
  },
  tableRoot: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  tableTitle: {
    flex: '1 1 100%',
  },
  soltaLogo: {
    display: 'block',
    width: 80,
  },
  margin5: {
    marginTop: 5,
  },
  margin7: {
    marginTop: 7,
  },
  margin10: {
    marginTop: 10,
  },
  margin15: {
    marginTop: 15,
  },
  margin20: {
    marginTop: 20,
  },
  margin30: {
    marginTop: 30,
  },
  margin35: {
    marginTop: 35,
  },
  margin60: {
    marginTop: 60,
  },
  logo: {
    marginTop: 30,
    width: 155,
    '@media (max-width: 1025px)': {
      width: 100,
      marginTop: 26,
    },
  },
  h1: {
    fontFamily: 'Roboto',
    fontWeight: 700,
    color: '#5A9BAB',
    textAlign: 'right',
    textTransform: 'uppercase',
    fontSize: 42,
    '@media (max-width: 1025px)': {
      fontSize: 28,
    },
  },
  blueRow: {
    width: '100%',
    height: 40,
    backgroundColor: '#5A9BAB',
  },
  logoHeader: {
    backgroundColor: '#fff',
    //marginBottom: 30,
  },
  textRight: {
    paddingTop: 30,
    textAlign: 'right',
  },
  soltaBackground: {
    backgroundColor: soltaColor,
  },
  headerBar: {
    paddingTop: 40,
    paddingBottom: 40,
    paddingLeft: 20,
    paddingRight: 20,
    //marginBottom: 10,
  },
  whiteText: {
    color: white,
  },
  searchField: {
    margin: theme.spacing(1),
    width: '90%',
  },
  addBrandBox: {
    padding: 40,
    maxWidth: 400,
  },

  userSideBar: {
    borderRight: `8px solid ${soltaColor}`,
    height: '100%',
  },
  appRoot: {
    display: 'flex',
    flexFlow: 'column',
    height: '100%',
  },
  autoShipApp: {
    flex: 2,
  },
  gridRoot: {
    height: '100%',
  },
  sideBarPadding: {
    padding: 20,
  },
  utilityBar: {
    backgroundColor: grey[500],
    padding: '20px 40px',
    color: white,
    '@media (max-width:1440px)': {
      padding: '10px 20px',
    },
  },
  fullWidth: {
    width: '100%',
    display: 'block',
    height: '100%',
  },
  marginAutshipForm: {
    marginTop: 40,
    marginBottom: 60,
  },
  searchForm: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: '90%',
  },
  autoFillSearchForm: {
    width: '100%',
    root: {
      padding: 0,
      margin: 0,
      //width: '100%',
    },
    '& .MuiInput-underline:before': {
      borderBottom: 'none !important',
    },
    '& .MuiFormLabel-root': {
      color: soltaColor,
      paddingLeft: 10,
    },
    '& .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot': {
      paddingRight: 0,
    },
    '& .MuiAutocomplete-inputRoot[class*="MuiInput-root"] .MuiAutocomplete-input:first-child': {
      padding: 0,
      margin: 0,
    },
    '& .MuiInputBase-root': {
      paddingLeft: 10,
    },
    '& .MuiAutocomplete-endAdornment': {
      top: 'calc(50% - 20px)',
    },
  },
  tierBadge: {
    maxWidth: 50,
    width: '100%',
    display: 'block',
  },
  tierTrophy: {
    maxWidth: 50,
    width: '100%',
    display: 'block',
  },
  companyTierBlock: {
    paddingLeft: 40,
    paddingRight: 40,
  },
  listColor: {
    color: list,
  },
  bronzeColor: {
    color: bronze,
  },
  silverColor: {
    color: silver,
  },
  goldColor: {
    color: gold,
  },
  platinumColor: {
    color: platinum,
  },
  emeraldColor: {
    color: emerald,
  },
  rubyColor: {
    color: ruby,
  },
  diamondColor: {
    color: diamond,
  },
  ListBackgroundColor: {
    backgroundColor: list,
  },
  BronzeBackgroundColor: {
    backgroundColor: bronze,
  },
  SilverBackgroundColor: {
    backgroundColor: silver,
  },
  GoldBackgroundColor: {
    backgroundColor: gold,
  },
  PlatinumBackgroundColor: {
    backgroundColor: platinum,
  },
  EmeraldBackgroundColor: {
    backgroundColor: emerald,
  },
  RubyBackgroundColor: {
    backgroundColor: ruby,
  },
  DiamondBackgroundColor: {
    backgroundColor: diamond,
  },
  ListColor: {
    color: list,
  },
  BronzeColor: {
    color: bronze,
  },
  SilverColor: {
    color: silver,
  },
  GoldColor: {
    color: gold,
  },
  PlatinumColor: {
    color: platinum,
  },
  EmeraldColor: {
    color: emerald,
  },
  RubyColor: {
    color: ruby,
  },
  DiamondColor: {
    color: diamond,
  },
  tierNames: {
    fontSize: 34,
    fontWeight: 400,
    '@media (max-width: 1921px)': {
      fontSize: 28,
    },
    '@media (max-width: 1650px)': {
      fontSize: 24,
    },
    '@media (max-width: 1440px)': {
      fontSize: 21,
    },
  },
  orderBarFooter: {
    backgroundColor: grey[500],
    height: 110,
  },
  companyCharts: {
    display: 'flex',
    minHeight: '100%',
    flexDirection: 'column',
  },
  chartGroup: {
    flex: 1,
  },
  orderBarButton: {
    paddingRight: 30,
  },
  chartGraph: {
    position: 'relative',
    marginBottom: 50,
  },
  autoshipMin: {
    marginBottom: 10,
  },
  dashedLinesContainer: {
    position: 'absolute',
    top: 21,
    height: 46,
    width: ' 100%',
  },

  dashedSingleLinesContainer: {
    position: 'absolute',
    top: 28,
    height: 46,
    width: ' 100%',
  },
  firstLinebox: {
    height: '100%',
    display: 'inline-block',
    position: 'absolute',
    width: 2,
    //left: '60.3777%',
    top: 0,
    opacity: 0.6,
  },
  middleLinebox: {
    height: '100%',
    display: 'inline-block',
    position: 'absolute',
    width: 4,
    //left: '71.6%',
    top: 0,
  },
  lastLinebox: {
    height: '100%',
    display: 'block',
    position: 'absolute',
    width: 2,
    right: 0,
    top: 0,
  },

  firstLineText: {
    //height: '100%',
    display: 'block',
    position: 'absolute',
    //left: 'calc(60.3777% - 150px )',
    bottom: -32,
    width: 150,
    textAlign: 'right',
    opacity: 0.6,
  },

  firstLineTextTop: {
    //height: '100%',
    display: 'block',
    position: 'absolute',
    //left: 'calc(60.3777% - 150px )',
    top: -25,
    width: 150,
    textAlign: 'right',
    opacity: 0.6,
  },

  middleLineText: {
    //height: '100%',
    display: 'block',
    position: 'absolute',
    //left: 'calc(71.6% - 75px )',
    bottom: -32,
    width: 150,
    textAlign: 'center',
  },

  middleLineTextTop: {
    //height: '100%',
    display: 'block',
    position: 'absolute',
    //left: 'calc(71.6% - 75px )',
    top: -46,
    width: 150,
    textAlign: 'center',
  },

  autoShipMinText: {
    display: 'block',
    //left: 'calc(71.6% - 75px )',
    //width: '71.6%',
    textAlign: 'right',
  },

  lastLineText: {
    //height: '100%',
    display: 'block',
    position: 'absolute',
    right: -75,
    bottom: -32,
    width: 150,
    textAlign: 'center',
    '@media (max-width:1900px)': {
      textAlign: 'right',
      right: 0,
    },
  },

  lastLineTextTop: {
    //height: '100%',
    display: 'block',
    position: 'absolute',
    right: -75,
    top: -46,
    width: 150,
    textAlign: 'center',
  },

  lastLineTextBottom: {
    //height: '100%',
    display: 'block',
    position: 'absolute',
    right: -75,
    bottom: -32,
    width: 150,
    textAlign: 'center',
  },

  lastLineTextBottomTop: {
    //height: '100%',
    display: 'block',
    position: 'absolute',
    right: -75,
    top: -46,
    width: 150,
    textAlign: 'center',
  },

  dashedLine: {
    width: 3,
    backgroundColor: grey[700],
  },

  dashedLineMiddle: {
    width: 6,
    backgroundColor: grey[700],
  },
  chartNumbers: {
    '@media (max-width:1921px)': {
      fontSize: '0.8rem',
    },
    '@media (max-width:1440px)': {
      fontSize: '0.75rem',
    },
  },

  chartNumbersSingleTier: {
    '@media (max-width:1921px)': {
      fontSize: '0.8rem',
    },
  },

  fullHeight: {
    height: '100%',
  },
  label: {
    color: white,
    //fontSize: '1rem',
  },
  labelMargin: {
    //marginRight: 8,
    //marginLeft: 8,
    marginRight: 0,
  },
  underline: {
    //textDecoration: 'underline',
    textTransform: 'uppercase',
    fontWeight: 700,
  },
  utilityIcons: {
    fontSize: 45,
    color: white,
    '@media (max-width:1440px)': {
      fontSize: 35,
    },
  },
  calculationBar: {
    backgroundColor: soltaColor,
    color: white,
    padding: 20,
    paddingTop: 40,
    //height: '100%',
  },

  calculationBarBottom: {
    backgroundColor: grey[700],
    color: white,
    padding: 20,
    paddingTop: 40,
    //height: '100%',
  },
  orderFormSection: {
    display: 'flex',
    minHeight: '100%',
    flexDirection: 'column',
  },

  tierBarBlock: {
    marginTop: 40,
    marginBottom: 40,
    //flex: 1,
  },
  orderFormBarSection: {
    flexGrow: 0,
    zIndex: 400,
  },

  orderFormBottomSection: {
    //flexDirection: 'row',
  },
  orderForm: {
    padding: 40,
  },
  priceHeader: {
    paddingLeft: 40,
    paddingRight: 40,
    paddingTop: 15,
    paddingBottom: 15,
  },
  whiteBackground: {
    backgroundColor: white,
    height: '100%',
  },
  flexDisplay: {
    display: 'flex',
  },
  boldFont: {
    fontWeight: 700,
  },
  orderBarMoney: {
    fontSize: 34,
    fontWeight: 400,
  },
  checkMarkIcons: {
    //width: '100%',
    fontSize: 36,
  },
  uppercaseType: {
    textTransform: 'uppercase',
  },
  monthBox: {
    paddingLeft: 20,
    //paddingTop: 20,
    paddingRight: 30,
    borderBottom: '1px solid rgba(0,0,0, 0.12)',
    borderRight: '1px solid rgba(0,0,0, 0.12)',
    cursor: 'pointer',
  },
  totalBox: {
    paddingLeft: 20,
    color: white,
    textTransform: 'uppercase',
  },
  greyText: {
    color: greyText,
  },
  monthCopy: {
    fontWeight: 700,
    fontSize: 21,
    '@media (max-width:1921px)': {
      fontSize: 16,
    },
  },
  monthAmount: {
    fontWeight: 400,
    fontSize: 28,
    '@media (max-width:1440px)': {
      fontSize: 18,
      paddingLeft: 5,
    },
  },
  brandLogos: {
    maxWidth: 165,
    width: '100%',
  },
  brandBlock: {
    //paddingLeft: 20,
    //paddingTop: 20,
    //paddingRight: 30,
    borderBottom: '1px solid rgba(0,0,0, 0.12)',
    borderRight: '10px solid rgba(0,0,0, 0.12)',
  },
  selectedMonth: {
    borderRight: `10px solid ${soltaColor}`,
  },
  selectedBrandBox: {
    borderRight: `10px solid ${soltaColor}`,
  },
  notSelectedMonth: {
    opacity: 0.5,
  },
  orderFormBrand: {
    fontWeight: 100,
    color: greyText,
    fontSize: 34,
    '@media (max-width:1675px)': {
      fontSize: 21,
    },
  },
  orderHeaderText: {
    color: soltaColor,
    fontWeight: 700,
  },
  soltaLines: {
    backgroundColor: soltaColor,
    width: '100%',
    marginTop: 10,
  },
  itemRow: {
    paddingTop: 10,
    paddingBottom: 10,
    '&:nth-child(even)': {
      backgroundColor: lightGrey,
    },
  },
  searchName: {
    color: soltaColor,
    algin: 'left',
    cursor: 'pointer',
    //fontSize: 16,
  },
  editText: {
    opacity: 0.6,
  },
  searchHeader: {
    color: soltaColor,
    fontWeight: 500,
    fontSize: 14,
    textTransform: 'uppercase',
  },
  thickLine: {
    height: 2,
    backgroundColor: greyText,
  },
  thickLineSolta: {
    height: 2,
    backgroundColor: soltaColor,
    marginBottom: 10,
  },
  orderListHeaders: {
    textTransform: 'uppercase',
    fontSize: 20,
    color: greyText,
  },
  promoNotReached: {
    opacity: 0.3,
  },
  whiteLoad: {
    color: white,
  },
  sideSearchBarBox: {
    maxHeight: 180,
    overflowY: 'auto',
    width: '100%',
  },
  priceListBlock: {
    maxHeight: 450,
    //minHeight: 500,
    overflowY: 'auto',
  },
  priceItem: {
    '&:nth-child(even)': {
      backgroundColor: lightGrey,
    },
  },
  pricePadd: {
    paddingBottom: 5,
    paddingTop: 5,
  },
  promoInfo: {
    fontSize: '0.875rem',
    marginLeft: 5,
    marginTop: 2,
  },
  trophyIcon: {
    maxWidth: 30,
  },
  halfTransparent: {
    opacity: 0.5,
  },

  deleteWarning: {
    fontWeight: 700,
    color: red[800],
  },
  nav: {
    //marginBottom: 60,
    zIndex: 100,
  },
});

export default styles;
