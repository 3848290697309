import * as ROLES from '../../constants/roles';
import * as ROUTES from '../../constants/routes';

export const signIn = (credentials) => {
  return (dispatch, getState, { getFirebase }) => {
    const firebase = getFirebase();

    firebase
      .auth()
      .signInWithEmailAndPassword(credentials.email, credentials.password)
      .then(() => {
        console.log('login was a success');
        dispatch({ type: 'LOGIN_SUCCESS' });
      })
      .catch((err) => {
        dispatch({ type: 'LOGIN_ERROR', err });
      });
  };
};

export const signOut = () => {
  return (dispatch, getState, { getFirebase }) => {
    const firebase = getFirebase();
    console.log('lets sign out?');
    firebase
      .auth()
      .signOut()
      .then(() => {
        window.location.href = ROUTES.LOGIN;
        dispatch({ type: 'SIGNOUT_SUCCESS' });
      });
  };
};

export const createNewUser = (newUser) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();
    const firestore = getFirestore();
    //console.log('this is my password', newUser.password);
    firebase
      .auth()
      .createUserWithEmailAndPassword(newUser.email, newUser.password)
      .then((resp) => {
        const userID = resp.user.uid;

        //console.log('this is my response after I create my user', resp);
        //console.log('this is my newUser', newUser);
        return firestore
          .collection('users')
          .doc(resp.user.uid)
          .set({
            firstName: newUser.firstname,
            lastName: newUser.lastname,
            fullName: newUser.firstname + ' ' + newUser.lastname,
            initials: newUser.firstname[0] + newUser.lastname[0],
            company: [newUser.company],
            email: newUser.email,
            phone: newUser.phone,
            role: newUser.role,
            userAdded: new Date(),
          })
          .then((resp) => {
            //Update the company with the new users
            //console.log('this is my resp', resp);
            if (newUser.role === ROLES.COMPANY) {
              return firestore
                .collection('companies')
                .doc(newUser.company)
                .update({
                  owner: firestore.FieldValue.arrayUnion(userID),
                })
                .then(() => {
                  dispatch({ type: 'NEW_USER_CREATED' });
                });
            } else if (newUser.role === ROLES.STAFF) {
              return firestore
                .collection('companies')
                .doc(newUser.company)
                .update({
                  staff: firestore.FieldValue.arrayUnion(userID),
                })
                .then(() => {
                  dispatch({ type: 'NEW_USER_CREATED' });
                });
            } else {
              dispatch({ type: 'NEW_USER_CREATED' });
            }
          })
          .catch((err) => {
            console.log('this is my error', err.message);
          });
      })
      .catch((err) => {
        dispatch({ type: 'NEW_USER_ERROR', err });
      });
  };
};

export const updateUserInfoDB = (user) => {
  return (dispatch, getState, { getFirestore }) => {
    const firestore = getFirestore();
    //console.log('this is my user info now', user);

    let companies = [];

    for (let i = 0; i < user.companyCount; i++) {
      companies.push(user['company' + i + 'ID']);
    }
    //console.log('this is my new company list', companies);
    firestore
      .collection('users')
      .doc(user.id)
      .update({
        firstName: user.firstName,
        lastName: user.lastName,
        fullName: user.firstName + ' ' + user.lastName,
        initials: user.firstName[0] + user.lastName[0],
        company: companies,
        role: user.role,
      })
      .then(() => {
        dispatch({ type: 'USER_DB_INFO_UPDATED' });
      })
      .catch((err) => {
        dispatch({ type: 'USER_DB_INFO_ERROR', err });
      });
  };
};

export const updateUserEmailInfo = (user) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();
    const firestore = getFirestore();

    const currentUser = firebase.auth().currentUser;
    const credential = firebase.auth.EmailAuthProvider.credential(user.originalEmail, user.currentPassword);
    currentUser
      .reauthenticateWithCredential(credential)
      .then(() => {
        currentUser
          .updateEmail(user.email)
          .then(() => {
            firestore
              .collection('users')
              .doc(user.userId)
              .update({
                email: user.email,
              })
              .then(() => {
                dispatch({ type: 'USER_EMAIL_HAS_BEEN_UPDATED' });
              })
              .catch((err) => {
                dispatch({ type: 'USER_EMAIL_NOT_UPDATED', err });
              });
          })
          .catch((err) => {
            dispatch({ type: 'USER_ID_EMAIL_NOT_UPDATED', err });
          });
      })
      .catch((err) => {
        dispatch({ type: 'CHANGE_EMAIL_REAUTHENTICATION_ERROR', err });
      });
  };
};

export const updateUserPassword = (user) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();

    const currentUser = firebase.auth().currentUser;
    const credential = firebase.auth.EmailAuthProvider.credential(user.email, user.currentPassword);
    currentUser
      .reauthenticateWithCredential(credential)
      .then(() => {
        currentUser
          .updatePassword(user.newPassword)
          .then(() => {
            dispatch({ type: 'USER_PASSWORD_WAS_UPDATED' });
          })
          .catch((err) => {
            dispatch({ type: 'USER_PASSWORD_WAS_NOT_UPDATED', err });
          });
      })
      .catch((err) => {
        dispatch({ type: 'CHANGE_PASSWORD_REAUTHENTICATION_ERROR', err });
      });
  };
};

export const sendEmailPasswordReset = (user) => {
  return (dispatch, getState, { getFirebase }) => {
    const firebase = getFirebase();
    //console.log('this is my user email', user.email);

    firebase
      .auth()
      .sendEmailPasswordReset(user.email)
      .then(() => {
        dispatch({ type: 'USER_EMAIL_RESET_SENT' });
      })
      .catch((err) => {
        dispatch({ type: 'USER_EMAIL_RESET_NOT_SENT', err });
      });
  };
};

export const closeAuthPopUp = () => {
  return (dispatch) => {
    dispatch({ type: 'CLOSE_AUTH_POPUP' });
  };
};

export const addUserFunction = (user) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();

    const sendUser = firebase.functions().httpsCallable('addUserFromAdmin');
    dispatch({ type: 'STARTING_USER_CREATION' });

    sendUser(user).then((result) => {
      //console.log('this is my result back', result.data);
      if (result.data.success === true) {
        //console.log('this is my result back', result.data);
        dispatch({ type: 'USER_WAS_CREATED' });
      } else if (result.data.success === false) {
        dispatch({ type: 'FAILED_TO_CREATE_USER', result });
      }
    });
  };
};

export const createUserListFunction = (user) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();

    const sendUser = firebase.functions().httpsCallable('createUserList');
    dispatch({ type: 'STARTING_USER_CREATION' });

    sendUser(user).then((result) => {
      //console.log('this is my result back', result.data);
      if (result.data.success === true) {
        //console.log('this is my result back', result.data);
        dispatch({ type: 'USER_WAS_CREATED' });
      } else if (result.data.success === false) {
        dispatch({ type: 'FAILED_TO_CREATE_USER', result });
      }
    });
  };
};

export const sortUserCompanies = (user) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();

    const sendUser = firebase.functions().httpsCallable('userCompanyToArray');
    dispatch({ type: 'STARTING_USER_CREATION' });

    sendUser(user).then((result) => {
      //console.log('this is my result back', result.data);
      if (result.data.success === true) {
        //console.log('this is my result back', result.data);
        dispatch({ type: 'USER_WAS_CREATED' });
      } else if (result.data.success === false) {
        dispatch({ type: 'FAILED_TO_CREATE_USER', result });
      }
    });
  };
};

export const updateUserLoggedInOn = (userID) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();

    firestore
      .collection('users')
      .doc(userID)
      .update({
        lastLoggedIn: new Date(),
      })
      .then(() => {
        dispatch({ type: 'USER_LOGIN_DATE_UPDATED' });
      })
      .catch((err) => {
        dispatch({ type: 'USER_LOGIN_DATE_WAS_NOT_UPDATED', err });
      });
  };
};

export const userAgreedToTerms = (user) => {
  return (dispatch, getState, { getFirestore }) => {
    const firestore = getFirestore();

    firestore
      .collection('users')
      .doc(user)
      .update({
        agreeToTerms: new Date(),
      })
      .then(() => {
        dispatch({ type: 'USER_AGREED_TO_TERMS' });
      })
      .catch((err) => {
        dispatch({ type: 'ERROR_USER_AGREED_TO_TERMS', err });
      });
  };
};

export const createEmailUser = (newUser) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();
    const firestore = getFirestore();

    firebase.analytics().logEvent('sign_up', { method: 'Email' });

    firebase
      .auth()
      .createUserWithEmailAndPassword(newUser.emailAddress, newUser.password)
      .then((userResp) => {
        return firestore
          .collection('users')
          .doc(userResp.user.uid)
          .set({
            firstName: newUser.firstName,
            lastName: newUser.lastName,
            fullName: newUser.firstName + ' ' + newUser.lastName,
            initials: newUser.firstName[0] + newUser.lastName[0],
            promos: {},
            email: newUser.emailAddress,
            role: 'doctor',
            userAdded: firestore.FieldValue.serverTimestamp(),
          })

          .then((r) => {
            dispatch({ type: 'NEW_USER_CREATED' });
          })
          .catch((err) => {
            console.log('this is my creating error ', err);
            dispatch({ type: 'NEW_USER_ERROR', err });
          });
      })
      .catch((err) => {
        console.log('this is my error ', err);
        dispatch({ type: 'NEW_USER_ERROR', err });
      });
  };
};
