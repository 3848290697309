const initState = {
  promoError: null,
  promoMessage: null,
  promoMessagePopup: false,
  promoErrorPopup: false,
  promoTechDoc: [],
  openMessage: false,
  searchingForPromoUser: false,
  searchMessage: null,
  promoUser: null,
  submitPromoStep: 0,
  exportUsers: [],
  exportClinics: [],
};

const promoReducer = (state = initState, action) => {
  switch (action.type) {
    case 'CLOSE_PROMO_POPUPS':
      return {
        ...state,
        promoError: null,
        promoMessage: null,
        promoMessagePopup: false,
        promoErrorPopup: false,
      };

    case 'PROMO_ADDED':
      //console.log('promo has been added')
      return {
        ...state,
        promoError: null,
      };
    case 'DOCTOR_SIGNED_UP_FOR_PROMO':
      //console.log('promo has been added')
      return {
        ...state,
        promoMessage: 'Clinic has been succesfully enrolled in the promo',
        promoMessagePopup: true,
      };
    case 'PROMO_ALREADY_ADDED':
      //console.log('promos is already in the system')
      return {
        ...state,
        promoError: 'promo already exists',
      };
    case 'PROMO_WAS_NOT_ADDED_TO_DATABASE':
      //console.log('promo Failed to be hadded to Database')
      return {
        ...state,
        promoError: action.err.message,
      };
    case 'PROMO_NO_DOCTORS_FOUND':
      return {
        ...state,
        promoError: 'No doctors have the promo technology',
      };
    case 'PROMO_DOCTORS_W_TECHNOLOGY':
      return {
        ...state,
        promoTechDoc: action.resp,
      };
    case 'PROMO_ERROR_LOOKING_FOR_DOCS_TECH':
      return {
        ...state,
        promoError: action.err.message,
      };
    case 'SUBMITTED_PROMO_APPROVED':
      return {
        ...state,
        promoError: null,
      };
    case 'ERROR_APPROVING_SUBMITTED_PROMO':
      return {
        ...state,
        promoError: action.err.message,
      };
    case 'EXPORT_CONSUMERS_REMINDER':
      return {
        ...state,
        exportUsers: action.exportList,
      };
    case 'EXPORT_CLINIC_FINDINGS':
      return {
        ...state,
        exportClinics: [...state.exportClinics, action.clinicReport],
      };
    default:
      return state;
  }
};

export default promoReducer;
