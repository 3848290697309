import authReducer from './authReducer';
//import companyReducer from './companyReducer';
//import coursesReducer from './coursesReducer';
import brandReducer from './brandReducer';
import promoReducer from './promoReducer';
import technologyReducer from './technologyReducer';
//import quizReducer from './quizReducer';
import staffReducer from './staffReducer';
//import tierReducer from './tierReducer';
import itemReducer from './itemReducer';
//import autoShipReducer from './autoShipReducer';
import myStoryReducer from './myStoryReducer';
import { combineReducers } from 'redux';
import { firestoreReducer } from 'redux-firestore';
import { firebaseReducer } from 'react-redux-firebase';

const rootReducer = combineReducers({
  firestore: firestoreReducer,
  firebase: firebaseReducer,
  auth: authReducer,
  //company: companyReducer,
  brand: brandReducer,
  technology: technologyReducer,
  //courses: coursesReducer,
  promos: promoReducer,
  //quiz: quizReducer,
  staff: staffReducer,
  //tiers: tierReducer,
  items: itemReducer,
  myStory: myStoryReducer,
  //autoShip: autoShipReducer,
});

export default rootReducer;
