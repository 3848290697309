/********************************************************************************/
/*  File Name: myStoryReducer.js                                                */
/*  Date:      Apr 08 2021                                                      */
/*  Author:    Christopher Cressman                                             */
/*  Purpose:   Reducer for the My Story App                                     */
/********************************************************************************/

const initState = {
  myStoryError: null,
  myStoryMessage: null,
  myStoryPopUp: false,
  myStoryErrorPopup: false,
  uploadStatus: 0,
  uploadStarted: false,
  profileModal: false,
  clinicModal: false,
  beforeAfterModal: false,
  pageUploaded: false,
};

const myStoryReducer = (state = initState, action) => {
  switch (action.type) {
    case 'CLOSE_MY_STORY_POPUPS':
      return {
        ...state,
        myStoryPopUp: false,
        myStoryErrorPopup: false,
        myStoryError: null,
        myStoryMessage: null,
      };
    case 'OPEN_PROFILE_MODALE':
      return {
        ...state,
        profileModal: true,
      };
    case 'CLOSE_PROFILE_MODALE':
      return {
        ...state,
        profileModal: false,
      };
    case 'START_UPLOAD_PROGRESS':
      return {
        ...state,
        uploadStarted: true,
      };
    case 'UPDATE_UPLOAD_PROGRESS':
      console.log('this is my upload progress', action);
      return {
        ...state,
        uploadStatus: action.progress,
      };
    case 'UPLOAD_COMPLETE_PROFILE_UPDATED':
      return {
        ...state,
        myStoryPopUp: true,
        myStoryMessage: 'Your profile image has been uploaded and your profile has been updated',
        profileModal: false,
      };
    case 'USER_PROFILE_UPDATED':
      return {
        ...state,
        myStoryPopUp: true,
        myStoryMessage: 'Your profile has been updated',
        profileModal: false,
      };
    case 'PAGE_ADDED':
      return {
        ...state,
        pageUploaded: true,
        myStoryPopUp: true,
        myStoryMessage: 'Your page has been added',
      };
    case 'UPLOADING_PAGE':
      return {
        ...state,
        pageUploaded: false,
      };
    case 'PAGE_REMOVED':
      return {
        ...state,
        myStoryPopUp: true,
        myStoryMessage: 'Your page has been deleted',
      };
    case 'PAGE_EDITS_SAVED':
      return {
        ...state,
        //pageUploaded: true,
        myStoryPopUp: true,
        myStoryMessage: 'Your page has been updated',
      };

    case 'PAGE_EDITS_BEFORE_UPDATED':
      return {
        ...state,
        pageUploaded: true,
        myStoryPopUp: true,
        myStoryMessage: 'Your page has been updated',
      };
    case 'PAGE_EDITS_AFTER_UPDATED':
      return {
        ...state,
        pageUploaded: true,
        myStoryPopUp: true,
        myStoryMessage: 'Your page has been updated',
      };
    default:
      return state;
  }
};

export default myStoryReducer;
