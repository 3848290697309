import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
//import { firestoreConnect } from 'react-redux-firebase';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import styles from '../Styles';

import Grid from '@material-ui/core/Grid';

//Actions

const INITIAL_STATE = {
  //loading: false,
  searchCompany: '',
};

class Header extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ...INITIAL_STATE,
    };
  }

  componentDidMount() {
    this.setState({ loading: true });
  }

  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const {
      classes, //t
    } = this.props;
    //const { searchCompany } = this.state;

    return (
      <div className={classes.logoHeader}>
        <div className={`logo-strip ${classes.soltaBackground} ${classes.headerBar}`}>
          <div className={classes.root}>
            <Grid container spacing={2} alignitems='center'>
              <Grid item xs={12}></Grid>
            </Grid>
          </div>
        </div>
      </div>
    );
  }
}

Header.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  //console.log('this is my state', state);
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    //loadAutoShipCompany: ({ clinicID, userID }) => dispatch(loadAutoShipCompany({ clinicID, userID })),
  };
};

export default compose(withStyles(styles), withTranslation(), connect(mapStateToProps, mapDispatchToProps))(Header);
