export const LANDING = '/';
export const LANDINGAUTH = '/a/:authID';
export const LOGIN = '/login';
export const LOGOUT = '/logout';
export const HOME = '/home';
export const ACCOUNT = '/account';
export const ADMIN = '/admin';
export const SIGN_UP = '/signup';
export const PASSWORD_FORGET = '/pw-forget';
export const DOCTOR_UPLOAD = '/upload-doctor';
export const PURE_UPLOAD = '/upload-tiers';
export const ITEM_UPLOAD = '/upload-items';
export const SALES_REP_UPLOAD = '/upload-sales-rep';
export const CALENDAR = '/calendar';
export const SEND = '/send';
export const SALES_SHEETS = '/sales-sheets';
export const USERS = '/users/:id';
export const USER = '/users/';
export const COMPANY = '/company/:id';
export const COMPANIES = '/company';
export const EDIT_COMPANY_ID = '/company/edit/:id';
export const EDIT_COMPANY = '/company/edit/';
export const COURSES = '/courses/';
export const COURSE = '/courses/:id';
export const EDIT_COURSE = '/courses/edit/:id';
export const EDIT_COURSE_PAGE = '/courses/edit/page/:id';
export const TECH = '/technology';
export const PROMOS = '/promos';
export const QUIZZES = '/quizzes/';
export const EDIT_QUIZZES_PAGE = '/quizzes/edit/:id';
export const EDIT_QUIZZES = '/quizzes/edit/';
export const TIERS = '/tiers';
export const AUTOSHIP_ADMIN = '/autoship-admin';
export const EDIT_VIEW = '/edit/:id';
export const VIEW_VIEW = '/view/:id';
export const VIEW_ALL_ORDERS = '/autoship-orders';
