const initState = {
    brandError: null
}

const brandReducer = (state = initState, action) => {
    switch (action.type) {
        case 'BRAND_ADDED':
            console.log('Brand has been added')
            return {
                ...state,
                brandError: null
            }
        case 'BRAND_IMAGE_WAS_UPLOADED':
            console.log('Brands logo has been uploaded')
            return {
                ...state,
                brandError: null

            }
        case 'BRAND_ALREADY_ADDED':
            console.log('Brands is already in the system')
            return {
                ...state,
                brandError: 'Brand already exists'

            }
        case 'BRAND_WAS_NOT_ADDED_TO_DATABASE':
            console.log('Brand Failed to be hadded to Database')
            return {
                ...state,
                brandError: action.err.message

            }

        case 'BRAND_IMAGE_FAILED_TO_UPLOAD':
            console.log('Brands Image Failed to be uploaded to the server')
            return {
                ...state,
                brandError: action.err.message

            }
        default:
            return state;
    }
}

export default brandReducer;