const initState = {
  authError: null,
  userSignOut: false,
  authMessage: null,
  authLoading: false,
};

const authReducer = (state = initState, action) => {
  switch (action.type) {
    case 'LOGIN_ERROR':
      return {
        ...state,
        authError: 'Login Failed',
      };
    case 'LOGIN_SUCCESS':
      return {
        ...state,
        userSignOut: false,
      };
    case 'SIGNOUT_SUCCESS':
      console.log('signout success');
      return {
        ...state,
        userSignOut: true,
      };
    case 'NEW_USER_CREATED':
      console.log('New User has been created');
      return {
        ...state,
        authError: null,
      };
    case 'NEW_USER_ERROR':
      console.log('User was not created');
      return {
        ...state,
        authError: action.err.message,
      };
    case 'USER_DB_INFO_UPDATED':
      console.log('Users information has been updated');
      return {
        ...state,
        authError: null,
      };
    case 'USER_DB_INFO_ERROR':
      console.log('Users information has not been udpated');
      return {
        ...state,
        authError: action.err.message,
      };
    case 'USER_EMAIL_HAS_BEEN_UPDATED':
      console.log('Users login email has been updated in the database and online');
      return {
        ...state,
        authError: null,
      };
    case 'USER_EMAIL_NOT_UPDATED':
      console.log('Users email has NOT been updated in the database');
      return {
        ...state,
        authError: action.err.message,
      };
    case 'USER_ID_EMAIL_NOT_UPDATED':
      console.log('Users Login email has NOT been updated');
      return {
        ...state,
        authError: action.err.message,
      };
    case 'CHANGE_EMAIL_REAUTHENTICATION_ERROR':
      console.log('User Failed to login while attempting to change their login Email');
      return {
        ...state,
        authError: action.err.message,
      };
    case 'USER_PASSWORD_WAS_NOT_UPDATED':
      console.log('Users Password was not updated');
      return {
        ...state,
        authError: action.err.message,
      };
    case 'CHANGE_PASSWORD_REAUTHENTICATION_ERROR':
      console.log('Original Password was not correct');
      return {
        ...state,
        authError: action.err.message,
      };
    case 'USER_PASSWORD_WAS_UPDATED':
      console.log('Users password has been updated!');
      return {
        ...state,
        authError: null,
      };
    case 'USER_EMAIL_RESET_SENT':
      console.log('An Email has been sent to the user to reset their password.');
      return {
        ...state,
        authError: null,
      };
    case 'USER_EMAIL_RESET_NOT_SENT':
      console.log('Failed to send the user a reset password email');
      return {
        ...state,
        authError: action.err.message,
      };

    case 'STARTING_USER_CREATION':
      return {
        ...state,
        authMessage: 'Adding a user',
        authLoading: true,
      };
    case 'USER_WAS_CREATED':
      return {
        ...state,
        authMessage: 'User was added',
        authError: null,
        authLoading: false,
      };

    case 'FAILED_TO_CREATE_USER':
      console.log('this is my message', action.result);
      return {
        ...state,
        authMessage: null,
        authError: action.result.data.message,
        authLoading: false,
      };
    default:
      return state;
  }
};

export default authReducer;
