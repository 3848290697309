import React, { Component } from 'react';
//import { Link } from 'react-router-dom';
/* import * as ROUTES from '../../constants/routes';
import * as ROLES from '../../constants/roles';
 */ //import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import { signOut } from '../../redux/actions/authActions';
//import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
/* import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
 */ //import MenuItem from '@material-ui/core/MenuItem';
//import Menu from '@material-ui/core/Menu';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
/* import SwipeableDrawer from '@material-ui/core/SwipeableDrawer'; */
import { withStyles } from '@material-ui/core/styles';
import styles from '../Styles';
/* import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import HomeIcon from '@material-ui/icons/Home';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import LaptopIcon from '@material-ui/icons/Laptop'; */
import { Redirect } from 'react-router-dom';
import SoltaLogo from '../../images/solta_logo.svg';

//import ExitToAppIcon from '@material-ui/icons/ExitToApp';

const INITIAL_STATE = {
  //loading: false,
  menuDrawer: false,
  anchor: 'left',
  redirect: false,
  redirectLink: null,
};

class Navigation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ...INITIAL_STATE,
    };
  }

  //const classes = useStyles();
  //const [anchorEl, setAnchorEl] = React.useState(null);
  //const open = Boolean(anchorEl);

  //console.log(props)

  handleMenu = (event) => {
    this.setState({
      anchorEll: event.currentTarget,
    });
  };

  handleClose = () => {
    this.setState({
      anchorEl: null,
    });
  };

  openDrawer = () => {
    this.setState({
      menuDrawer: true,
    });
  };

  toggleDrawer = () => {
    this.setState({
      menuDrawer: false,
    });
  };

  clickLink = ({ link }) => {
    //console.log('this is my link', link);
    this.setState({
      menuDrawer: false,
      redirectLink: link,
      redirect: true,
    });
  };

  render() {
    const {
      //t,
      classes,
      //auth,
      profile,
    } = this.props;
    const {
      //menuDrawer, //anchor,
      redirect,
      redirectLink,
    } = this.state;

    //console.log('logo props profile', profile);
    return (
      <div>
        {redirect ? <Redirect push to={redirectLink} /> : null}

        <AppBar position='static'>
          <Toolbar className='nav-wrapper white darken-3'>
            <div>
              {!profile.isEmpty ? (
                <div>
                  {/* <IconButton aria-label='account of current user' aria-controls='menu-appbar' aria-haspopup='true' onClick={this.openDrawer}>
                    <MenuIcon />
                  </IconButton>
                  <SwipeableDrawer anchor={anchor} open={menuDrawer} onClose={this.toggleDrawer} onOpen={this.openDrawer}>
                    <List className={classes.list}>
                      {profile.role === ROLES.SUPERADMIN ? (
                        <div>
                          <ListItem button component='a' onClick={() => this.clickLink({ link: ROUTES.LANDING })}>
                            <ListItemIcon>
                              <HomeIcon />
                            </ListItemIcon>
                            <ListItemText primary='Home' />
                          </ListItem>
                          <Divider />

                          <ListItem button onClick={() => this.clickLink({ link: ROUTES.USER })}>
                            <ListItemIcon>
                              <PeopleAltIcon />
                            </ListItemIcon>
                            <ListItemText primary='Users' />
                          </ListItem>
                          <Divider />
                          <ListItem button onClick={() => this.clickLink({ link: ROUTES.TECH })}>
                            <ListItemIcon>
                              <LaptopIcon />
                            </ListItemIcon>
                            <ListItemText primary='Technologies' />
                          </ListItem>
                        </div>
                      ) : (
                        ''
                      )}
                      <Divider />
                      <ListItem button onClick={this.props.signOut}>
                        <ListItemIcon>
                          <ExitToAppIcon />
                        </ListItemIcon>
                        <ListItemText primary='Logout' />
                      </ListItem>
                    </List>
                  </SwipeableDrawer> */}
                </div>
              ) : null}
            </div>
            <Typography variant='h6' className={classes.title}>
              onDemand
            </Typography>

            <img src={SoltaLogo} alt='Solta Logo' className={` ${classes.right} ${classes.soltaLogo}`} />
          </Toolbar>
        </AppBar>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile,
  };
};

const mapDisptachToProps = (dispatch) => {
  return {
    signOut: () => dispatch(signOut()),
  };
};

Navigation.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default compose(withTranslation(), withStyles(styles), connect(mapStateToProps, mapDisptachToProps))(Navigation);
