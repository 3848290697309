import { createMuiTheme } from '@material-ui/core/styles';

const metaTheme = createMuiTheme({
  typography: {
    fontFamily: ['Inter', '-apple-system', 'BlinkMacSystemFont', '"Segoe UI"', 'Roboto', '"Helvetica Neue"', 'Arial', 'sans-serif', '"Apple Color Emoji"', '"Segoe UI Emoji"', '"Segoe UI Symbol"'].join(','),
    //fontSmoothing: 'antialiased',
  },
});

metaTheme.typography.body1 = {
  fontWeight: 500,
  fontSize: '1rem',
  lineHeight: 1.5,
  /*
  font-size: 1rem;
    font-family: Inter,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
    font-weight: 400;
    line-height: 1.5;
    */
};

export default metaTheme;
